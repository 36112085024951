import React from "react"
import { Row, Col } from "react-bootstrap"

const BadgeDownload = ({
    root,
    filename,
    name,
    text,
    squareHref,
    horizontalHref,
    bookHref
    
}) => {
    return (
        <Row className="mt-5">
            <Col md={6}>
                <img
                    src={`${root}/${filename}.png`}
                    className="img-fluid"
                    alt=""
                    style={{ maxHeight: "350px" }}
                />
            </Col>
            <Col>
                <h3>{name}</h3>
                <p className="para" style={{ whiteSpace: "pre-wrap" }}>
                    {text}
                </p>
                {squareHref && (

                    <a
                    href={squareHref}
                    className="btn btn-primary m-3"
                    target="_blank"
                    >
                    Download
                </a>
                )}
                {bookHref && (
                    <a
                        href={bookHref}
                        className="btn btn-primary m-3"
                        target="_blank"
                    >
                    Book Ticket
                    </a>
                )}
                
                
            </Col>
            
        </Row>
    )
}

export default BadgeDownload
