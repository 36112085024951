import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import Section from "../../../components/Section"
import { useStaticQuery, graphql, Link } from "gatsby"
import BadgeDownload from "../../../components/BadgeDownload"


const IndexPage = () => {
    const root = "/img/download/awards"


    return (
        <Layout pageTitle="Virtual Awards - Award Winner Badges">
            <Row>
                <Col>
                    <Link to="/awards/small-business-awards">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}>
                <p className="para">
                        CONGRATULATIONS! You're here because you have won a Small Business award. Let's CELEBRATE! 
                        Don't keep your win quiet - raise your profile as
                        a Women's Business Club award winner by sharing your winner or runner up badge!
                    </p>
                    <p className="para">
                    Use these award badges to announce your win on social media. 
                    You can also add these to your email and website footers. Select 
                    'transparent background' when downloading to remove the white backgroud 
                    if needed to put over and image or coloured background.
                    </p>
                    
                    <h3>Sponsor</h3>
                    <p className="para">
                    We'd love for you to tag your award sponsor! You can find more information and a link to thier website at the bottom of the page{" "}
                        <a href="https://womensbusiness.club/awards/small-business">
                            here.
                        </a></p>
                    <p className="para">Find them and tag them on social media - they'd love to congratulate you!
                    </p>
                </Col>
            </Row>
            <BadgeDownload
                root={root}
                name="Runner Up Badge"
                filename="awards-runnerup-badge"
                text="Find and download your award. Select 
                'transparent background' when downloading to remove the white backgroud 
                if needed to put over and image or coloured background."
                squareHref="https://www.canva.com/design/DAFRW5Nni3U/MT9906c12Yhj0V1X08RaUw/view?utm_content=DAFRW5Nni3U&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
           
           />
           <BadgeDownload
                root={root}
                name="Winner Badge"
                filename="awards-winner-badge"
                text="Find and download your award. Select 
                'transparent background' when downloading to remove the white backgroud 
                if needed to put over and image or coloured background."
                squareHref="https://www.canva.com/design/DAFRQ992s-g/CW7f-ypNa9Ts0lTnK8yukw/view?utm_content=DAFRQ992s-g&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
           
           />
        </Layout>
    )
}

export default IndexPage
